import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import { FaRoute } from 'react-icons/fa';
import { LanguageContext } from '../contexts/LanguageContext';
import usePreviousPage from '../hooks/usePreviousPage';
import { useToast } from '../contexts/ToastContext';
import ServiceAddressCheckpoint from '../components/ServiceAddressCheckpoint';
import ServiceAddressFloorplan from '../components/ServiceAddressFloorplan';
import ServiceAddressVisitHistory from '../components/ServiceAddressVisitHistory';
import ServiceAddressPlannedWork from '../components/ServiceAddressPlannedWork';
import ServiceAddressScheduleModal from '../components/ServiceAddressScheduleModal';
import ServiceAddressUpdateInfo from '../components/ServiceAddressUpdateInfo';
import './ServiceAddress.css';
import config from '../config';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

const ServiceAddress = () => {
  const { behandlingsadrId } = useParams();
  const navigate = useNavigate();
  const { translations } = useContext(LanguageContext);
  const notify = useToast();
  const [addressData, setAddressData] = useState(null);
  const [visitCount, setVisitCount] = useState(0);
  const [nextVisit, setNextVisit] = useState(null);
  const [futureAssignments, setFutureAssignments] = useState([]);
  const [visitHistory, setVisitHistory] = useState([]);
  const [pests, setPests] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [isCheckpointsVisible, setIsCheckpointsVisible] = useState(false);
  const [floorPlans, setFloorPlans] = useState([]);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isUpdateInfoModalOpen, setIsUpdateInfoModalOpen] = useState(false);

  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userid');
  const { previousPage } = usePreviousPage();

  const fetchServiceCardData = useCallback(async (behandlingsadrId) => {
    try {
      const addressResponse = await apiClient.get(`${config.apiUrl}/getServiceCardCust/${behandlingsadrId}?token=${token}`);
      const avtale_id = addressResponse.data.data[0].avtale_id;

      const [
        visitCountResponse,
        nextVisitResponse,
        futureAssignmentsResponse,
        visitHistoryResponse,
        pestsResponse,
        equipmentResponse,
        floorPlansResponse
      ] = await Promise.all([
        apiClient.get(`${config.apiUrl}/getCountVisitCurrentYear/${behandlingsadrId}?token=${token}`),
        apiClient.get(`${config.apiUrl}/getServiceCardNextVisit/${behandlingsadrId}?token=${token}`),
        apiClient.get(`${config.apiUrl}/getFutureAssignmentServiceCard/${behandlingsadrId}?token=${token}`),
        apiClient.get(`${config.apiUrl}/getServicecardAssignmnt/${behandlingsadrId}?token=${token}`),
        apiClient.get(`${config.apiUrl}/getBehandling/${avtale_id}?token=${token}`),
        apiClient.get(`${config.apiUrl}/getUtstyrInk/${avtale_id}?token=${token}`),
        apiClient.get(`${config.apiUrl}/getFloorplans/${avtale_id}?token=${token}`)
      ]);

      setAddressData(addressResponse.data.data[0]);
      setVisitCount(visitCountResponse.data.data[0].count);
      setNextVisit(nextVisitResponse.data.data[0]);
      setFutureAssignments(futureAssignmentsResponse.data.data);
      setVisitHistory(visitHistoryResponse.data.data);
      setPests(pestsResponse.data.data);
      setEquipment(equipmentResponse.data.data);
      setFloorPlans(floorPlansResponse.data.data);
    } catch (error) {
      console.error('Error fetching service card data:', error);
      notify('error', translations.failedToFetchServiceCardData || 'Failed to fetch service card data.');
    }
  }, [token, notify, translations]);

  useEffect(() => {
    if (!behandlingsadrId) {
      navigate('/search-address');
    } else {
      fetchServiceCardData(behandlingsadrId);
    }
  }, [behandlingsadrId, navigate, fetchServiceCardData]);

   const customerAddress = addressData ? `${addressData.behandlingsadr}, ${addressData.poststed}` : '';
  
   useEffect(() => {
    if (addressData) {
      const geocodeAddress = async () => {
        const address = `${addressData.behandlingsadr}, ${addressData.poststed}`;
        const geocodeResponse = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`
        );
        const geocodeData = await geocodeResponse.json();
  
        if (geocodeData.length > 0) {
          const map = new maplibregl.Map({
            container: 'map',
            style: 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json',
            center: [parseFloat(geocodeData[0].lon), parseFloat(geocodeData[0].lat)],
            zoom: 14,
          });
  
          new maplibregl.Marker()
            .setLngLat([parseFloat(geocodeData[0].lon), parseFloat(geocodeData[0].lat)])
            .addTo(map);
  
          return () => {
            map.remove(); // Rydd opp når komponenten unmountes
          };
        } else {
          console.error('Geocoding failed: No results found');
          notify('error', translations.failedToLocateAddress || 'Failed to locate address on map.');
        }
      };
  
      geocodeAddress();
    }
  }, [addressData, notify, translations]);
  
  
  const handleCreateReport = async () => {
    try {
      const besokResponse = await apiClient.get(`${config.apiUrl}/getServiceAddressBesokId/${behandlingsadrId}?token=${token}`);
      const type_besok_id = besokResponse.data.data[0].type_besok_id;

      if (type_besok_id === -1) {
        notify('error', translations.invalidVisitId || 'Invalid visit ID. Cannot start report.');
        return;
      }

      const reportData = {
        behandlingsadr_id: behandlingsadrId,
        type_besok_id: type_besok_id,
        user_id: userId,
        dato_start: new Date().toISOString().split('T')[0],
        status_id: 1
      };

      const createReportResponse = await apiClient.post(`${config.apiUrl}/createReportNew/?token=${token}`, reportData);
      const { oppdrag_id } = createReportResponse.data;
      navigate(`/legacy/createreport/${behandlingsadrId}/${oppdrag_id}`);
    } catch (error) {
      console.error('Error creating report:', error);
      notify('error', translations.failedToCreateReport || 'Failed to create report. Please try again.');
    }
  };

  const handleGetDirections = () => {
    const address = `${addressData.behandlingsadr}, ${addressData.poststed}`;
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`, '_blank');
  };

  const handleDownloadReport = (oppdrag_id) => {
    const url = `${config.apiUrl}/getFileReport/${oppdrag_id}?token=${token}`;
    window.open(url, '_blank');
  };

  const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Måned er 0-indeksert
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

  const openScheduleModal = () => {
    setIsScheduleModalOpen(true);
  };

  const closeScheduleModal = () => {
    setIsScheduleModalOpen(false);
  };

  const openUpdateInfoModal = () => {
    setIsUpdateInfoModalOpen(true);
  };

  const closeUpdateInfoModal = () => {
    setIsUpdateInfoModalOpen(false);
  };

  const handleSaveUpdateInfo = () => {
    fetchServiceCardData(behandlingsadrId);
    setIsUpdateInfoModalOpen(false);
  };

  const handleSave = () => {
    fetchServiceCardData(behandlingsadrId);
    setIsScheduleModalOpen(false);
  };

  const handleDeletePlannedWork = () => {
    fetchServiceCardData(behandlingsadrId);
  };
  
  const renderNextVisit = () => {
    if (!nextVisit || !nextVisit.next_visit) {
      return (
        <div className="table-cell-right" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <button className="button schedule-button yellow-button" onClick={openScheduleModal}>
            {translations.schedule || 'Schedule'}
          </button>
        </div>
      );
    }
  
    const visitDate = new Date(nextVisit.next_visit);
    const today = new Date();
    const isPast = visitDate < today;
  
    const buttonClass = isPast ? 'yellow-button' : 'green-button';
  
    return (
      <div className="table-cell-right" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <button
          className={`button schedule-button ${buttonClass}`}
          disabled // Sørger for at knappen alltid er deaktivert hvis det finnes en dato
          
        >
          {formatDate(nextVisit.next_visit)}
        </button>
      </div>
    );
  };
  

  return (
    <MainLayout title={translations.serviceAddress || 'Service Address'}>
      <div className="service-card-container">
        {addressData ? (
          <div className="service-card">
            <div className="service-card-header">
            <div className="service-card-info">
              <h2>
                <Link to={`/customeroverview/${addressData.kunde_id}`}>
                  {addressData.navn}
                </Link>
              </h2>
              <div><strong>Kunde nr:</strong></div>
              <div>{addressData.kundenr}</div>
              <div><strong>Kontaktperson:</strong></div>
              <div>{addressData.kontakt}</div>
              <div><strong>Telefon:</strong></div>
              <div>{addressData.telefon}</div>
              <div><strong>E-postadresse (rapport):</strong></div>
              <div>{addressData.mail_to}</div>
              <div><strong>E-postadresse (Kopi av rapport):</strong></div>
              <div>{addressData.mail_cc}</div>
              <div><strong>Annen informasjon:</strong></div>
              <div>{addressData.notat}</div>
              <div><strong>Ruteområde:</strong></div>
              <div>{addressData.omrade}</div>
            </div>
              <div className="map-and-button">
              <div
                id="map"
                style={{ width: '100%', height: '300px', borderRadius: '8px' }}
              ></div>
                <button className="button get-directions-button" onClick={handleGetDirections}>
                  <FaRoute style={{ marginRight: '8px' }} /> {translations.getDirections || 'Get Directions'}
                </button>
              </div>
            </div>
            <button className="button edit-button" onClick={openUpdateInfoModal}>{translations.editInformation || 'Edit Information'}</button>
            <div className="service-card-body">
              <div className="table-section">
                <div className="table-row">
                  <div className="table-cell"><strong>{translations.pestTreatment || 'Pest/Treatment'}:</strong></div>
                  <div className="table-cell-right"><strong>{translations.extraSanitation || 'Extra Sanitation'}</strong></div>
                </div>
                {pests.map((pest, index) => (
                  <div key={index} className="table-row">
                    <div className="table-cell">{pest.behandling}</div>
                    <div className="table-cell-right">{pest.extra_sanering ? <span className="green-check">&#10004;</span> : <span className="red-cross">&#10008;</span>}</div>
                  </div>
                ))}
              </div>
              <div className="table-section">
                <div className="table-row">
                  <div className="table-cell"><strong>{translations.equipmentType || 'Equipment Type'}:</strong></div>
                  <div className="table-cell-right"><strong>{translations.quantity || 'Quantity'}</strong></div>
                </div>
                {equipment.map((item, index) => (
                  <div key={index} className="table-row">
                    <div className="table-cell">{item.utstyr}</div>
                    <div className="table-cell-right">{item.antall}</div>
                  </div>
                ))}
              </div>
              <div className="table-section">
                <div className="table-row">
                  <div className="table-cell"><strong>{translations.numberOfVisitsPerYear || 'Number of Visits Per Year'}:</strong></div>
                  <div className="table-cell-right">{addressData.kontroller}</div>
                </div>
                <div className="table-row">
                  <div className="table-cell"><strong>{translations.numberOfVisitsThisYear || 'Number of Visits This Year'}:</strong></div>
                  <div className="table-cell-right">{visitCount}</div>
                </div>
                <div className="table-row">
                  <div className="table-cell"><strong>{translations.startDate || 'Start Date'}:</strong></div>
                  <div className="table-cell-right">{addressData.dato_start ? formatDate(addressData.dato_start) : translations.noStartDate || 'No Start Date'}</div>
                </div>
                <p></p>
                <div className="table-row">
                  <div className="table-cell">
                    <strong>{translations.nextVisit || 'Next Visit'}:</strong>
                  </div>
                  {renderNextVisit()}
                </div>
              </div>
            </div>
            <button className="button create-report-button" onClick={handleCreateReport}>{translations.createReport || 'Create Report'}</button>

            <ServiceAddressPlannedWork 
              futureAssignments={futureAssignments} 
              token={token}
              onDelete={handleDeletePlannedWork} 
            />

            <div className="expandable-section">
              <button className="expand-button" onClick={() => setIsCheckpointsVisible(!isCheckpointsVisible)}>
                {translations.checkpoints || 'Checkpoints'}
              </button>
              {isCheckpointsVisible && (
                <div className="expandable-content">
                  <ServiceAddressCheckpoint behandlingsadrId={behandlingsadrId} token={token} />
                </div>
              )}
            </div>

            <ServiceAddressFloorplan 
              behandlingsadrId={behandlingsadrId} 
              token={token} 
              floorPlans={floorPlans} 
              fetchServiceCardData={fetchServiceCardData} 
              customerAddress={customerAddress}
            />

            <ServiceAddressVisitHistory 
              visitHistory={visitHistory} 
              token={token} 
              handleDownloadReport={handleDownloadReport} 
            />

          </div>
        ) : (
          <p>{translations.loading || 'Loading'}</p>
        )}
      </div>
      <ServiceAddressScheduleModal 
        isOpen={isScheduleModalOpen}
        onClose={closeScheduleModal}
        behandlingsadrId={behandlingsadrId}
        token={token}
        onSave={handleSave} 
      />
      <ServiceAddressUpdateInfo
        isOpen={isUpdateInfoModalOpen}
        onClose={closeUpdateInfoModal}
        currentInfo={addressData?.notat}
        emailReport={addressData?.mail_to}
        emailReportCopy={addressData?.mail_cc}
        behandlingsadrId={behandlingsadrId}
        token={token}
        onSave={handleSaveUpdateInfo}
      />
    </MainLayout>
  );
};

export default ServiceAddress;
