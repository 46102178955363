import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';

const usePreviousPage = () => {
  const location = useLocation();
  const { translations = {} } = useContext(LanguageContext);
  const [previousPage, setPreviousPage] = useState('');
  const [previousPageName, setPreviousPageName] = useState('');

  useEffect(() => {
    const pageNames = {
      '/mainmenu': translations.home || 'Home',
      '/calendar': translations.calendar || 'Calendar',
      '/myprofile': translations.myProfile || 'My Profile',
      '/driving-routes': translations.drivingRoutes || 'Driving Routes',
      '/addresses': translations.addresses || 'Addresses',
      '/agreements': translations.agreements || 'Agreements',
      '/customers': translations.customers || 'Customers',
      '/search-address': translations.searchAddress || 'Search Address',
      '/new-inspection': translations.newInspection || 'New Inspection',
      '/my-complete-tasks': translations.myCompleteTasks || 'My Complete Tasks',
      '/incomplete-reports': translations.incompleteReports || 'Incomplete Reports',
      '/future-jobs': translations.futureJobs || 'Future Jobs',
      '/new-work-order': translations.newWorkOrder || 'New Work Order',
      '/serviceaddress': translations.serviceAddress || 'Service Address',
      '/customeroverview': translations.customerOverview || 'Customer Overview',
      '/createreport': translations.createReport || 'Create Report',
      '/customernew': translations.newCustomer || 'New Customer', 
      '/agreement-new': translations.newAgreement || 'New Agreement', 
      '/serviceaddress-new': translations.serviceAddressNew || 'Service Address New', 
    };

    const currentPath = location.pathname;
    const currentSearch = location.search;
    const previousPath = sessionStorage.getItem('previousPath');

    if (previousPath && currentPath + currentSearch !== previousPath) {
      setPreviousPage(previousPath);
      try {
        const previousPathname = new URL(previousPath, window.location.origin).pathname;
        setPreviousPageName(pageNames[previousPathname] || translations.back || 'Back');
      } catch (error) {
        console.error('Invalid previous path:', error);
      }
    }

    return () => {
      const currentStoredPath = sessionStorage.getItem('previousPath');
      // Oppdater kun hvis ingen annen eksplisitt verdi er satt
      if (!currentStoredPath || currentStoredPath === currentPath + currentSearch) {
        sessionStorage.setItem('previousPath', currentPath + currentSearch);
        console.log('Updated previousPath:', currentPath + currentSearch);
      }
    };
  }, [location, translations]);

  return { previousPage, previousPageName };
};

export default usePreviousPage;
