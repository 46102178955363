import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import './ServiceAddressScheduleModal.css';
import config from '../config';
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext

const ServiceAddressScheduleModal = ({ isOpen, onClose, behandlingsadrId, token, onSave }) => {
  const { translations } = useContext(LanguageContext); // Hent oversettelser fra LanguageContext
  const [selectedDate, setSelectedDate] = useState('');
  const [visitTypes, setVisitTypes] = useState([]);
  const [selectedVisitType, setSelectedVisitType] = useState('');
  const [loading, setLoading] = useState(false);

  // Hent verdier til dropdown Visit Type
  useEffect(() => {
    if (isOpen) {
      apiClient.get(`${config.apiUrl}/getVisitType/?token=${token}`)
        .then(response => {
          setVisitTypes(response.data.data);
        })
        .catch(error => {
          console.error('Error fetching visit types:', error);
          alert(translations['visitTypeLoadError'] || 'Failed to load visit types');
        });
      setSelectedDate(''); // Tilbakestill dato ved åpning
      setSelectedVisitType(''); // Tilbakestill valgt type ved åpning
    }
  }, [isOpen, token, translations]);

  const handleSave = async () => {
    if (!selectedDate || !selectedVisitType) {
      alert(translations['selectDateAndTypeError'] || 'Please select both a date and a visit type');
      return;
    }

    setLoading(true);
    const userId = localStorage.getItem('userid');
    const data = {
      type_besok_id: selectedVisitType,
      behandlingsadr_id: behandlingsadrId,
      user_id: userId,
      dato_start: selectedDate,
      status_id: 0
    };

    try {
      await apiClient.post(`${config.apiUrl}/CreateNextVisit/?token=${token}`, data);
      onSave(behandlingsadrId); // Oppdater data på siden
      onClose(); // Lukk modalen
    } catch (error) {
      console.error('Error saving next visit date:', error);
      alert(translations['saveVisitError'] || 'Failed to save the visit');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="schedule-modal"
      overlayClassName="schedule-overlay" // Dimming av skjermen bak modal
      contentLabel={translations['scheduleNextAppointment'] || 'Schedule Next Appointment'}
    >
      <h2>{translations['scheduleNextAppointment'] || 'Schedule Next Appointment'}</h2>
      <div className="form-group">
        <label>{translations['date'] || 'Date'}</label>
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="date-picker"
        />
      </div>
      <div className="form-group">
        <label>{translations['chooseVisitType'] || 'Choose Visit Type'}</label>
        <select
          value={selectedVisitType}
          onChange={(e) => setSelectedVisitType(e.target.value)}
          className="dropdown"
        >
          <option value="" disabled>
            {translations['selectVisitType'] || 'Select Visit Type'}
          </option>
          {visitTypes.map((type) => (
            <option key={type.type_besok_id} value={type.type_besok_id}>
              {type.type_besok}
            </option>
          ))}
        </select>
      </div>
      <div className="modal-actions">
        <button className="button cancel-sc-button" onClick={onClose} disabled={loading}>
          {translations['cancel'] || 'Cancel'}
        </button>
        <button className="button save-sc-button" onClick={handleSave} disabled={loading}>
          {loading ? translations['saving'] || 'Saving...' : translations['save'] || 'Save'}
        </button>
      </div>
    </Modal>
  );
};

export default ServiceAddressScheduleModal;
