/**
 * This file defines the Login component for the React application.
 * It handles user authentication by collecting username and password,
 * sending a login request to the server, and managing local storage for session data.
 */
import React, { useState, useEffect, useContext } from 'react';
import apiClient, { storeTokens } from '../api/apiClient'; // Importer apiClient og storeTokens
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import './Login.css';
import { LanguageContext } from '../contexts/LanguageContext';

Modal.setAppElement('#root');

const Login = () => {
  // Henter translations og changeLanguage fra LanguageContext
  const { translations, changeLanguage } = useContext(LanguageContext);

  // State for inputfelt og feilmeldinger
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Laster tidligere lagret brukernavn hvis "Remember Me" er aktivert
  useEffect(() => {
    const rememberedUsername = localStorage.getItem('rememberMe');
    if (rememberedUsername) {
      setUsername(rememberedUsername);
      setRememberMe(true);
    }
  }, []);

  // Håndterer innlogging
  const handleLogin = async () => {
    try {
      const response = await apiClient.post(`${config.apiUrl}/loginApp`, {
        username,
        password,
      });

      console.log('Login response:', response.data); // Debug: log the response

      if (response.data.success) {
        const token = response.data.token;
        const refreshToken = response.data.refreshToken;
        const user = response.data.user;

        console.log('User object:', user); // Debug: log the user object

        if (!user.id) {
          setError(translations.missingUserId || 'Missing userId in response');
          return;
        }

        // Lagre tokens og språk
        storeTokens(token, refreshToken);
        localStorage.setItem('userid', user.id);
        localStorage.setItem('name', user.navn);
        localStorage.setItem('fname', user.fname);
        localStorage.setItem('is_technician', user.is_technician);
        localStorage.setItem('start_position', user.start_position);
        localStorage.setItem('end_position', user.end_position);
        localStorage.setItem('language_id', user.language_id);

        // Oppdater språket i LanguageContext
        changeLanguage(user.language_id); // Oppdater språket dynamisk

        if (rememberMe) {
          localStorage.setItem('rememberMe', username);
        } else {
          localStorage.removeItem('rememberMe');
        }

        navigate('/mainmenu'); // Naviger til hovedmenyen
      } else {
        setError(response.data.message || translations.invalidUsernameOrPassword || 'Invalid username or password');
      }
    } catch (error) {
      console.error('Login error:', error); // Debug: log the error
      if (error.response) {
        setError(error.response.data.message || translations.invalidUsernameOrPassword || 'Invalid username or password');
      } else if (error.request) {
        setError(translations.connectionError || 'Unable to connect to the server. Please check your internet connection or try again later.');
      } else {
        setError(translations.unexpectedError || 'An unexpected error occurred. Please try again.');
      }
    }
  };

  // Håndterer glemt passord
  const handleForgotPassword = async () => {
    try {
      const response = await apiClient.post(`${config.apiUrl}/forgetpassword`, {
        username: email,
      });
      if (response.data.success) {
        setMessage(translations.passwordResetLinkSent || 'Password reset link sent to your email');
      } else {
        setMessage(translations.passwordResetFailed || 'Failed to send password reset link');
      }
    } catch (error) {
      setMessage(translations.passwordResetError || 'Error sending password reset link');
    }
  };

  return (
    <div
      className="login-page"
      style={{
        backgroundImage: "url('/loginbg.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
      }}
    >
      <div className="login-container">
        <h1>{translations.appName || 'GNX PestControl'}</h1>
        <input
          type="text"
          placeholder={translations.usernamePlaceholder || 'Username'}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder={translations.passwordPlaceholder || 'Password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label>
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          {translations.rememberMe || 'Remember Me'}
        </label>
        <button onClick={handleLogin}>{translations.login || 'Login'}</button>
        <button onClick={() => setShowForgotPasswordModal(true)}>
          {translations.forgotPassword || 'Forgot Password'}
        </button>
        {error && <div className="error">{error}</div>}
        <Modal
          isOpen={showForgotPasswordModal}
          onRequestClose={() => setShowForgotPasswordModal(false)}
          contentLabel={translations.forgotPasswordModal || 'Forgot Password Modal'}
          className="Modal"
          overlayClassName="Overlay"
        >
          <div className="modal-content">
            <h2>{translations.forgotPassword || 'Forgot Password'}</h2>
            <label>
              {translations.email || 'Email'}:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <button className="button" onClick={handleForgotPassword}>
              {translations.submit || 'Submit'}
            </button>
            <button
              className="button cancel-button"
              onClick={() => setShowForgotPasswordModal(false)}
            >
              {translations.cancel || 'Cancel'}
            </button>
            {message && <div className="message">{message}</div>}
          </div>
        </Modal>
      </div>
      <footer className="footer">
        <p>&copy; 2024 GITO AS - {translations.allRightsReserved || 'All Rights Reserved'}</p>
      </footer>
    </div>
  );
};

export default Login;
