// src/config.js

const config = {
  apiUrl: 'https://api.demo.gnx.no:8445/api',
  googleMapsApiKey: 'AIzaSyDrHzOY8QzpscO3RehylhofGcQiGoarMqA', // Google Maps API-nøkkel
  googleMapsLibraries: ['places', 'geometry', 'marker'], // Libraries for Google Maps API
  googleMapsMapId: '4377fffee11226d', // Unikt Map ID
};

export default config;
