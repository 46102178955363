import React, { useState, useEffect, useContext } from 'react';
import apiClient from '../api/apiClient';
import { FaTrashAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import config from '../config';
import { LanguageContext } from '../contexts/LanguageContext';
import './ServiceAddressCheckpoint.css';

const ServiceAddressCheckpoint = ({ behandlingsadrId, token }) => {
    const { translations } = useContext(LanguageContext);
    const [checkpoints, setCheckpoints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [checkpointToDelete, setCheckpointToDelete] = useState(null);

    useEffect(() => {
        if (!behandlingsadrId) {
            setError(translations['missingAddressId'] || 'behandlingsadrId is missing');
            setLoading(false);
            return;
        }

        const fetchCheckpoints = async () => {
            try {
                const response = await apiClient.get(
                    `${config.apiUrl}/getCheckpointByServiceAddress/${behandlingsadrId}?token=${token}`
                );
                setCheckpoints(response.data.data || []);
            } catch (err) {
                setError(translations['fetchErrorCP'] || 'Failed to fetch checkpoints');
            } finally {
                setLoading(false);
            }
        };

        fetchCheckpoints();
    }, [behandlingsadrId, token, translations]);

    const openDeleteModal = (checkpointId) => {
        setCheckpointToDelete(checkpointId);
        setModalIsOpen(true);
    };

    const closeDeleteModal = () => {
        setModalIsOpen(false);
        setCheckpointToDelete(null);
    };

    const handleDelete = async () => {
        if (!checkpointToDelete) return;

        try {
            const response = await apiClient.delete(
                `${config.apiUrl}/delCheckpoint/${checkpointToDelete}?token=${token}`
            );

            if (response.status === 200) {
                setCheckpoints(checkpoints.filter(cp => cp.checkpoint_id !== checkpointToDelete));
                closeDeleteModal();
            }
        } catch (err) {
            setError(translations['deleteErrorCP'] || 'Failed to delete checkpoint');
        }
    };

    if (loading) {
        return <p>{translations['loadingCheckpoints'] || 'Loading checkpoints...'}</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (!checkpoints || checkpoints.length === 0) {
        return <p>{translations['noCheckpoints'] || 'No checkpoints found.'}</p>;
    }

    return (
        <div className="cp-checkpoint-list">
            {checkpoints.map((checkpoint) => (
                <div key={checkpoint.checkpoint_id} className="cp-checkpoint-card" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <p><strong>{checkpoint.checkpoint_type}</strong></p>
                        <p>{checkpoint.room} - {checkpoint.exact_location}</p>
                        <p>{checkpoint.bait_type}</p>
                    </div>
                    <button
                        onClick={() => openDeleteModal(checkpoint.checkpoint_id)}
                        className="task-footer-button delete-button"
                        style={{ marginLeft: 'auto' }}
                    >
                        <FaTrashAlt /> {translations['delete'] || 'Delete'}
                    </button>
                </div>
            ))}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeDeleteModal}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: '500px',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                    }
                }}
                contentLabel={translations['confirmDelete'] || 'Confirm Delete'}
            >
                <h2>{translations['confirmDelete'] || 'Confirm Delete'}</h2>
                <p>{translations['confirmDeleteTextCP'] || 'Are you sure you want to delete this checkpoint?'}</p>
                <button onClick={closeDeleteModal} className="task-footer-button resume-button">
                    {translations['cancel'] || 'Cancel'}
                </button>
                <button onClick={handleDelete} className="task-footer-button delete-button">
                    <FaTrashAlt /> {translations['confirmDelete'] || 'Confirm Delete'}
                </button>
            </Modal>
        </div>
    );
};

export default ServiceAddressCheckpoint;
