import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateReport.css';
import MainLayout from '../../components/MainLayout';
import { FaCamera } from 'react-icons/fa';
import apiClient from '../../api/apiClient';
import Compressor from 'compressorjs';
import config from '../../config';
import useUpdateReportProgress from '../../hooks/useUpdateReportProgress'; 
import EditImageModal from '../../components/EditImageModal';
import { useToast } from '../../contexts/ToastContext';
import OppdragInfoBanner from '../../components/OppdragInfoBanner';
import { LanguageContext } from '../../contexts/LanguageContext';
import Croppie from 'croppie';
import 'croppie/croppie.css';

const CreateReport = () => {
  const { behandlingsadrId, oppdrag_id } = useParams();
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasExistingPhoto, setHasExistingPhoto] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const fileInputRef = useRef(null);
  const croppieElementRef = useRef(null);
  const croppieRef = useRef(null);
  const token = localStorage.getItem('token');
  const { updateOppdragProgress } = useUpdateReportProgress();
  const notify = useToast();
  const { translations } = useContext(LanguageContext);

  useEffect(() => {
    const fetchExistingPhoto = async () => {
      try {
        const response = await apiClient.get(`${config.apiUrl}/getLocPhotoOppdrag/${oppdrag_id}/?token=${token}`);
        if (response.data && response.data.data && response.data.data.oppdrag_bilde) {
          setPreviewImage(response.data.data.oppdrag_bilde);
          setHasExistingPhoto(true);
        }
      } catch (error) {
        console.error('Error fetching existing photo:', error);
        notify('error', translations.fetchPhotoError || 'Failed to fetch existing photo. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchExistingPhoto();
  }, [oppdrag_id, token, notify, translations]);

  useEffect(() => {
    if (selectedFile && croppieElementRef.current) {
      initializeCroppie(selectedFile);
    }
  }, [selectedFile]);

  const handleCancel = async () => {
    const userConfirmed = window.confirm(
      translations.cancelConfirmationMessage ||
      'Are you sure you want to cancel? This will permanently delete the entire report, including all content and images. You will need to create a new report if you proceed'
    );
  
    if (!userConfirmed) {
      return;
    }
  
    try {
      await apiClient.post(`${config.apiUrl}/cancelReport/?token=${token}`, {
        oppdrag_id,
        slettet: 1,
        status_id: 3
      });
      navigate('/mainmenu');
    } catch (error) {
      console.error('Error cancelling report:', error);
      notify('error', translations.cancelReportError || 'Failed to cancel report. Please try again.');
    }
  };

  const handleFileOption = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      new Compressor(file, {
        quality: 0.6, 
        maxWidth: 1000,
        maxHeight: 1000,
        success: (compressedResult) => {
          const reader = new FileReader();
          reader.onloadend = (event) => {
            setSelectedFile(event.target.result);
          };
          reader.readAsDataURL(compressedResult);
        },
        error(err) {
          console.error('Error compressing file:', err);
          notify('error', translations.compressPhotoError || 'Failed to compress photo. Please try again.');
        }
      });
    }
  };

  const initializeCroppie = (imageUrl) => {
    if (!croppieElementRef.current) {
      console.error('Croppie element not found');
      return;
    }
    if (croppieRef.current) {
      croppieRef.current.destroy();
    }
    croppieRef.current = new Croppie(croppieElementRef.current, {
      viewport: { width: 300, height: 200, type: 'rectangle' },
      boundary: { width: '100%', height: 300 },
      showZoomer: true,
      enableResize: false,
      enforceBoundary: true,
      enableExif: true,
      minZoom: 0,
    });
    croppieRef.current.bind({ url: imageUrl }).catch((error) => {
      console.error('Error binding Croppie:', error);
    });
  };

  const handleSave = async () => {
    if (!selectedFile && !hasExistingPhoto) {
      notify('error', translations.noPhotoError || 'Please take a picture of the service address.');
      return;
    }

    if (croppieRef.current) {
      croppieRef.current.result({
        type: 'base64',
        size: { width: 600, height: 400 },
        format: 'jpeg',
        quality: 0.5
      }).then(async (base64) => {
        setPreviewImage(base64);
        setSelectedFile(base64);
        if (croppieRef.current) {
          croppieRef.current.destroy();
          croppieRef.current = null;
        }

        try {
          await apiClient.post(`${config.apiUrl}/addLocPhoto/?token=${token}`, {
            oppdrag_bilde: base64 || previewImage,
            oppdrag_id: oppdrag_id
          });

          const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/visittype/${behandlingsadrId}/${oppdrag_id}`, navigate);
          notify('dismiss');
          if (success) {
            notify('success', translations.photoUploadSuccess || 'Photo uploaded successfully.');
            navigate(`/legacy/visittype/${behandlingsadrId}/${oppdrag_id}`);
          }
        } catch (error) {
          console.error('Error uploading photo:', error);
          notify('error', translations.photoUploadError || 'Failed to upload photo. Please try again.');
        }
      }).catch((error) => {
        console.error('Error cropping image:', error);
        notify('error', translations.cropImageError || 'Failed to crop image. Please try again.');
      });
    } else {
      try {
        await apiClient.post(`${config.apiUrl}/addLocPhoto/?token=${token}`, {
          oppdrag_bilde: previewImage,
          oppdrag_id: oppdrag_id
        });

        const success = await updateOppdragProgress(token, oppdrag_id, behandlingsadrId, `/legacy/visittype/${behandlingsadrId}/${oppdrag_id}`, navigate);
        notify('dismiss');
        if (success) {
          notify('success', translations.photoUploadSuccess || 'Photo uploaded successfully.');
          navigate(`/legacy/visittype/${behandlingsadrId}/${oppdrag_id}`);
        }
      } catch (error) {
        console.error('Error uploading photo:', error);
        notify('error', translations.photoUploadError || 'Failed to upload photo. Please try again.');
      }
    }
  };

  return (
    <MainLayout title={translations.createReportTitle || "Create Report"}>
      <div className="cr-container">
        <OppdragInfoBanner />
        {isLoading ? (
          <div>{translations.loading || 'Loading...'}</div>
        ) : (
          <>
            <h2 className="cr-heading">{translations.takePicture || 'Take a picture of the service address:'}</h2>
            <div className="cr-box">
              <div className="cr-image-placeholder" style={{ display: previewImage || selectedFile ? 'none' : 'block' }} onClick={handleFileOption}>
                <FaCamera className="cr-camera-icon" />
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </div>
              {selectedFile && (
                <div id="croppie-container" ref={croppieElementRef} style={{ marginTop: '20px', width: '100%', maxWidth: '400px', height: '300px', border: '1px solid #ccc' }}></div>
              )}
              {previewImage && !selectedFile && (
                <div className="cr-preview-container" onClick={handleFileOption}>
                  <img src={previewImage} alt="Preview" className="cr-preview-image" />
                </div>
              )}
            </div>
            <div className="cr-buttons">
              <button className="cr-button cr-cancel-button" onClick={handleCancel}>{translations.cancel || 'Cancel'}</button>
              <button className="cr-button cr-save-button" onClick={handleSave}>{translations.save || 'Save'}</button>
            </div>
          </>
        )}
      </div>
      {previewImage && (
        <EditImageModal
          show={showEditModal}
          onClose={() => setShowEditModal(false)}
          imageUrl={previewImage}
          onSaveImage={(editedImage) => {
            setPreviewImage(editedImage);
            setSelectedFile(editedImage);
          }}
        />
      )}
    </MainLayout>
  );
};

export default CreateReport;
