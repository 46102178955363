import React, { useState, useEffect, useContext } from 'react';
import apiClient from '../api/apiClient';
import { FaSyncAlt, FaTrashAlt, FaEdit } from 'react-icons/fa';
import '../pages/ServiceAddress.css';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../contexts/ToastContext';
import config from '../config';
import Modal from 'react-modal';
import { LanguageContext } from '../contexts/LanguageContext';
import EventModal from '../components/EventModal';

const ServiceAddressPlannedWork = ({ futureAssignments, token, onDelete }) => {
  const { translations } = useContext(LanguageContext);
  const [isFutureAssignmentsVisible, setIsFutureAssignmentsVisible] = useState(false);
  const [assignments, setAssignments] = useState(futureAssignments);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // Tilstand for delete-modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Tilstand for edit-modal
  const [assignmentToDelete, setAssignmentToDelete] = useState(null);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const navigate = useNavigate();
  const notify = useToast();

  useEffect(() => {
    setAssignments(futureAssignments); // Oppdater assignments når futureAssignments prop'en oppdateres
  }, [futureAssignments]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '500px',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('nb-NO', options);
  };

  const handleStart = (assignment) => {
    const { behandlingsadr_id, oppdrag_id } = assignment;


    apiClient
      .post(`${config.apiUrl}/resumeFutureJob/?token=${token}`, {
        user_id: localStorage.getItem('userid'),
        oppdrag_id: oppdrag_id,
        status_id: 1,
      })
      .then((response) => {
        if (response.data.status === 'success') {
          navigate(`/legacy/CreateReport/${behandlingsadr_id}/${oppdrag_id}`);
        } else {
          notify('error', translations['startAssignmentError'] || 'Failed to start assignment');
        }
      })
      .catch((error) => {
        notify('error', translations['startAssignmentError'] || 'Error starting assignment');
      });
  };

  const openEditModal = (assignment) => {
    setSelectedAssignment({
      ...assignment,
      startdate: assignment.dato_start,
      user_id: assignment.user_id,
    });
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedAssignment(null);
    setIsEditModalOpen(false);
    if (onDelete) onDelete();
  };

  const openDeleteModal = (assignmentId) => {
    setAssignmentToDelete(assignmentId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setAssignmentToDelete(null);
  };

  const handleDelete = () => {
    if (!assignmentToDelete) return;

    apiClient
      .post(`${config.apiUrl}/cancelReport?token=${token}`, {
        oppdrag_id: assignmentToDelete,
        slettet: 1,
        status_id: 3,
      })
      .then((response) => {
        if (response.data.status === 'success') {
          notify('success', translations['deleteAssignmentSuccess'] || 'Assignment deleted successfully');
          setAssignments(assignments.filter((assignment) => assignment.oppdrag_id !== assignmentToDelete));
          closeDeleteModal();
          if (onDelete) onDelete();
        } else {
          notify('error', translations['deleteAssignmentError'] || 'Failed to delete assignment');
        }
      })
      .catch((error) => {
        notify('error', translations['deleteAssignmentError'] || 'Error deleting assignment');
      });
  };

  return (
    <div className="expandable-section">
      <button className="expand-button" onClick={() => setIsFutureAssignmentsVisible(!isFutureAssignmentsVisible)}>
        {translations['plannedWork'] || 'Planned Work'}
      </button>
      {isFutureAssignmentsVisible && (
        <div className="expandable-content">
          {assignments.length > 0 ? (
            assignments.map((assignment) => (
              <div key={assignment.oppdrag_id} className="assignment-card">
                <p>
                  <strong>{translations['date'] || 'Date'}:</strong> {formatDate(assignment.dato_start)}
                </p>
                <p>
                  <strong>{translations['type'] || 'Type'}:</strong> {assignment.type_besok}
                </p>
                <p>
                  <strong>{translations['customerName'] || 'Customer Name'}:</strong> {assignment.kundenavn}
                </p>
                <p>
                  <strong>{translations['address'] || 'Address'}:</strong> {assignment.behandlingsadr}
                </p>
                <p>
                  <strong>{translations['contractType'] || 'Contract Type'}:</strong> {assignment.avtaletype}
                </p>
                <div className="task-footer">
                  <button className="task-footer-button resume-button" onClick={() => handleStart(assignment)}>
                    <FaSyncAlt /> {translations['start'] || 'Start'}
                  </button>
                  <button className="task-footer-button edit-button" onClick={() => openEditModal(assignment)}>
                    <FaEdit /> {translations['edit'] || 'Edit'}
                  </button>
                  <button
                    className="task-footer-button delete-button"
                    onClick={() => openDeleteModal(assignment.oppdrag_id)}
                  >
                    <FaTrashAlt /> {translations['delete'] || 'Delete'}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>{translations['noPlannedAssignments'] || 'No Planned Assignments'}</p>
          )}
        </div>
      )}

      {/* Slettemodal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        style={customStyles}
        contentLabel={translations['confirmDelete'] || 'Confirm Delete'}
      >
        <h2>{translations['confirmDelete'] || 'Confirm Delete'}</h2>
        <p>{translations['confirmDeleteMessage'] || 'Are you sure you want to delete this assignment?'}</p>
        <button onClick={handleDelete} className="confirm-button">
          {translations['confirm'] || 'Confirm'}
        </button>
        <button onClick={closeDeleteModal} className="cancel-button">
          {translations['cancel'] || 'Cancel'}
        </button>
      </Modal>

      {/* Redigeringsmodal */}
      {isEditModalOpen && selectedAssignment && (
        <EventModal event={selectedAssignment} onClose={closeEditModal} />
      )}
    </div>
  );
};

export default ServiceAddressPlannedWork;
