import React, { useState, useEffect, useRef, useContext } from 'react';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient';
import config from '../config';
import { useParams, useNavigate } from 'react-router-dom';
import { FaUndo, FaRedo } from 'react-icons/fa';
import Compressor from 'compressorjs';
import { useToast } from '../contexts/ToastContext';
import { LanguageContext } from '../contexts/LanguageContext';
import './InspectionDetails.css';

const InspectionDetails = () => {
  const { inspectionId } = useParams();
  const navigate = useNavigate();
  const notify = useToast();
  const { translations } = useContext(LanguageContext);

  const [title, setTitle] = useState('');
  const [note, setNote] = useState('');
  const [photos, setPhotos] = useState([]);
  const [newPhotos, setNewPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fileInputRef = useRef(null);

  const token = localStorage.getItem('token');

  useEffect(() => {
    apiClient.get(`${config.apiUrl}/getInspectionDetails/${inspectionId}/?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          const inspection = response.data.data;
          setTitle(inspection.title);
          setNote(inspection.note);
          setPhotos(inspection.photos || []);
        }
      })
      .catch(error => {
        console.error('Error fetching inspection details:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    apiClient.get(`${config.apiUrl}/getInspectionPictures/${inspectionId}/?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          const inspectionPictures = response.data.data.map(picture => ({
            src: picture.picture,
            comment: picture.comment,
            id: picture.inspection_picture_id,
            rotation: 0,
          }));
          setPhotos(inspectionPictures);
        }
      })
      .catch(error => {
        console.error('Error fetching inspection pictures:', error);
      });
  }, [inspectionId, token]);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + photos.length + newPhotos.length > 10) {
      notify('error', translations['maxPhotosError'] || 'You can only upload up to 10 images.');
      return;
    }
    const newPhotosPromises = files.map((file) => new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 1000,
        maxHeight: 1000,
        success: (compressedResult) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Image = reader.result;
            const newPhoto = { src: base64Image, comment: '', rotation: 0 };
            setNewPhotos((prevNewPhotos) => [...prevNewPhotos, newPhoto]);
            resolve();
          };
          reader.readAsDataURL(compressedResult);
        },
        error: (err) => {
          console.error('Error compressing file:', err);
          notify('error', translations['compressionError'] || 'Failed to compress photo. Please try again.');
          reject(err);
        }
      });
    }));
    Promise.all(newPhotosPromises).then(() => {
      notify('success', translations['photosProcessed'] || 'All photos processed successfully.');
    });
  };

  const handleCommentChange = (e, index, isNewPhoto) => {
    const { value } = e.target;
    if (isNewPhoto) {
      const updatedNewPhotos = [...newPhotos];
      updatedNewPhotos[index].comment = value;
      setNewPhotos(updatedNewPhotos);
    } else {
      const updatedPhotos = [...photos];
      updatedPhotos[index].comment = value;
      setPhotos(updatedPhotos);
    }
  };

  const handleDelete = (index, isNewPhoto) => {
    if (isNewPhoto) {
      const updatedNewPhotos = [...newPhotos];
      updatedNewPhotos.splice(index, 1);
      setNewPhotos(updatedNewPhotos);
    } else {
      const updatedPhotos = [...photos];
      const [deletedPhoto] = updatedPhotos.splice(index, 1);
      apiClient.delete(`${config.apiUrl}/delInspectionPicture/?token=${token}`, {
        data: { inspection_picture_id: deletedPhoto.id }
      }).then(() => {
        notify('success', translations['photoDeleted'] || 'Image deleted successfully.');
        setPhotos(updatedPhotos);
      }).catch((error) => {
        console.error('Error deleting image:', error);
        notify('error', translations['deleteError'] || 'Failed to delete image. Please try again.');
      });
    }
  };

  const rotateImage = (index, isNewPhoto, direction) => {
    const degrees = direction === 'left' ? -90 : 90;

    if (isNewPhoto) {
      const updatedNewPhotos = [...newPhotos];
      updatedNewPhotos[index].rotation = (updatedNewPhotos[index].rotation + degrees + 360) % 360;
      setNewPhotos(updatedNewPhotos);
    } else {
      const updatedPhotos = [...photos];
      updatedPhotos[index].rotation = (updatedPhotos[index].rotation + degrees + 360) % 360;
      setPhotos(updatedPhotos);
    }
  };

  const applyRotationToImage = async (src, rotation) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (rotation === 90 || rotation === 270) {
          canvas.width = img.height;
          canvas.height = img.width;
        } else {
          canvas.width = img.width;
          canvas.height = img.height;
        }

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((rotation * Math.PI) / 180);
        if (rotation === 90 || rotation === 270) {
          ctx.drawImage(img, -img.height / 2, -img.width / 2, img.height, img.width);
        } else {
          ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height);
        }

        canvas.toBlob((blob) => {
          new Compressor(blob, {
            quality: 0.6,
            maxWidth: 1000,
            maxHeight: 1000,
            success: (compressedBlob) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(reader.result);
              };
              reader.readAsDataURL(compressedBlob);
            },
            error: (err) => {
              console.error('Error compressing rotated image:', err);
              resolve(canvas.toDataURL());
            }
          });
        }, 'image/jpeg', 0.8);
      };
    });
  };

  const saveInspectionDetails = async () => {
    const payload = {
      title: title,
      note: note,
      inspection_id: inspectionId
    };

    try {
      await apiClient.post(`${config.apiUrl}/updInspectionNotes/?token=${token}`, payload);

      const uploadPromises = newPhotos.map(async (photo) => {
        const rotatedSrc = await applyRotationToImage(photo.src, photo.rotation);
        const response = await apiClient.post(`${config.apiUrl}/addInspectionPicture/?token=${token}`, {
          inspection_id: inspectionId,
          picture: rotatedSrc,
          comment: photo.comment
        });
        const inspection_picture_id = response.data.inspection_picture_id;
        return { ...photo, id: inspection_picture_id, src: rotatedSrc };
      });

      const uploadedPhotos = await Promise.all(uploadPromises);
      setPhotos((prevPhotos) => [...prevPhotos, ...uploadedPhotos]);
      setNewPhotos([]);

      const updatePromises = photos.map(async (photo) => {
        const rotatedSrc = await applyRotationToImage(photo.src, photo.rotation);
        await apiClient.post(`${config.apiUrl}/updInspectionPicture/?token=${token}`, {
          updatedPicture: rotatedSrc,
          inspection_picture_id: photo.id,
          comment: photo.comment
        });
      });

      await Promise.all(updatePromises);

      notify('success', translations['inspectionSaved'] || 'Inspection details saved successfully!');
    } catch (error) {
      console.error('Error saving inspection details:', error);
      notify('error', translations['saveError'] || 'Failed to save inspection details. Please try again.');
    }
  };

  const handleBack = () => {
    if (newPhotos.length > 0 || title || note) {
      if (window.confirm(translations['unsavedChanges'] || 'You have unsaved changes, do you want to save them before leaving?')) {
        saveInspectionDetails().then(() => {
          navigate(`/new-inspection/${inspectionId}`);
        });
      } else {
        navigate(`/new-inspection/${inspectionId}`);
      }
    } else {
      navigate(`/new-inspection/${inspectionId}`);
    }
  };

  const handleComplete = async () => {
    if (!title) {
      notify('error', translations['titleRequired'] || 'Title is required.');
      return;
    }

    if (!note) {
      notify('error', translations['noteRequired'] || 'Notes are required.');
      return;
    }

    await saveInspectionDetails();

    setTimeout(() => {
      navigate('/mainmenu');
    }, 1000);
  };

  return (
    <MainLayout title={translations['inspectionDetails'] || 'Inspection Details'}>
      <div className="inspection-details-container">
        <div className="inspection-field">
          <label>{translations['title'] || 'Title'}</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={`inspection-input ${!title ? 'inspection-input-required' : 'inspection-input-filled'}`}
          />
        </div>
        <div className="inspection-field">
          <label>{translations['notes'] || 'Notes'}</label>
          <textarea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className={`inspection-textarea ${!note ? 'inspection-input-required' : 'inspection-input-filled'}`}
          />
        </div>
        <div className="inspection-field">
          <label>{translations['photos'] || 'Photos'}</label>
          <div className="photo-upload-container">
            {isLoading ? (
              <div>{translations['loading'] || 'Loading...'}</div>
            ) : (
              <>
                <div className="photo-list">
                  {photos.map((photo, index) => (
                    <div key={index} className="photo-item">
                      <div>
                        <img
                          src={photo.src}
                          alt="uploaded"
                          className="photo-preview-image"
                          style={{ transform: `rotate(${photo.rotation || 0}deg)` }}
                        />
                      </div>
                      <div className="photo-rotation-buttons">
                        <button className="photo-button" onClick={() => rotateImage(index, false, 'left')}><FaUndo /></button>
                        <button className="photo-button" onClick={() => rotateImage(index, false, 'right')}><FaRedo /></button>
                      </div>
                      <textarea
                        className="photo-textarea"
                        placeholder={translations['comment'] || 'Comment'}
                        value={photo.comment}
                        onChange={(e) => handleCommentChange(e, index, false)}
                      />
                      <button className="photo-button photo-delete-button" onClick={() => handleDelete(index, false)}>{translations['delete'] || 'Delete'}</button>
                    </div>
                  ))}
                  {newPhotos.map((photo, index) => (
                    <div key={index + photos.length} className="photo-item">
                      <div>
                        <img
                          src={photo.src}
                          alt="new upload"
                          className="photo-preview-image"
                          style={{ transform: `rotate(${photo.rotation || 0}deg)` }}
                        />
                      </div>
                      <div className="photo-rotation-buttons">
                        <button className="photo-button" onClick={() => rotateImage(index, true, 'left')}><FaUndo /></button>
                        <button className="photo-button" onClick={() => rotateImage(index, true, 'right')}><FaRedo /></button>
                      </div>
                      <textarea
                        className="photo-textarea"
                        placeholder={translations['comment'] || 'Comment'}
                        value={photo.comment}
                        onChange={(e) => handleCommentChange(e, index, true)}
                      />
                      <button className="photo-button photo-delete-button" onClick={() => handleDelete(index, true)}>{translations['delete'] || 'Delete'}</button>
                    </div>
                  ))}
                </div>
                <button className="photo-button photo-add-button" onClick={handleFileClick}>{translations['addMorePhotos'] || 'Add more photos'}</button>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </>
            )}
          </div>
        </div>
        <div className="inspection-buttons">
          <button className="inspection-button inspection-button-back" onClick={handleBack}>{translations['back'] || 'Back'}</button>
          <button className="inspection-button inspection-button-complete" onClick={handleComplete}>{translations['complete'] || 'Complete'}</button>
        </div>
      </div>
    </MainLayout>
  );
};

export default InspectionDetails;
