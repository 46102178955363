// src/components/OppdragInfoBanner.js
import React, { useEffect, useState, useContext } from 'react';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../contexts/ToastContext';
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext
import './OppdragInfoBanner.css';
import config from '../config';
import { FaClipboardList } from 'react-icons/fa';
import ControlPointModal from './ControlPointModal';

const OppdragInfoBanner = () => {
  const { behandlingsadrId, oppdrag_id } = useParams();
  const [oppdragInfo, setOppdragInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isControlPointModalOpen, setIsControlPointModalOpen] = useState(false);
  const navigate = useNavigate();
  const notify = useToast();
  const token = localStorage.getItem('token');
  const { translations } = useContext(LanguageContext); // Bruk translations fra LanguageContext

  useEffect(() => {
    const fetchOppdragInfo = async () => {
      try {
        const response = await apiClient.post(`${config.apiUrl}/getOppdragInfoBanner/?token=${token}`, {
            behandlingsadr_id: behandlingsadrId,
            oppdrag_id: oppdrag_id,
        });
        if (response.data && response.data.status === 'success' && response.data.data.length > 0) {
          setOppdragInfo(response.data.data[0]);
        } else {
          throw new Error('No data returned');
        }
      } catch (error) {
        notify('error', translations['fetchError'] || 'Failed to fetch oppdrag info. Redirecting...');
        setTimeout(() => {
          navigate('/mainmenu');
        }, 3000);
      } finally {
        setLoading(false);
      }
    };

    fetchOppdragInfo();
  }, [behandlingsadrId, oppdrag_id, token, notify, navigate, translations]);

  if (loading) {
    return <div className="banner-loading">{translations['loading'] || 'Loading oppdrag info...'}</div>;
  }

  return (
    <div className="oppdrag-info-banner">
      {oppdragInfo ? (
        <>
          <p><strong>{translations['customer'] || 'Kunde'}:</strong> {oppdragInfo.kunde_navn} - {oppdragInfo.behandlingsadresse}</p>
          <p><strong>{translations['agreement'] || 'Avtale'}:</strong> {oppdragInfo.avtale_navn} - {translations['task'] || 'Oppdrag'}: {oppdragInfo.oppdrag_id} </p>
          <button 
            className="control-point-button"
            onClick={() => setIsControlPointModalOpen(true)}
          >
            <FaClipboardList />
          </button>
          <ControlPointModal 
            isOpen={isControlPointModalOpen} 
            onRequestClose={() => setIsControlPointModalOpen(false)}
            behandlingsadrId={behandlingsadrId}
          />
        </>
      ) : (
        <p>{translations['noTaskInfo'] || 'Ingen oppdrag info tilgjengelig.'}</p>
      )}
    </div>
  );
};

export default OppdragInfoBanner;