import React, { useState, useRef, useEffect, useContext } from 'react';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import Modal from 'react-modal';
import { FaTrashAlt } from 'react-icons/fa';
import Compressor from 'compressorjs';
import Map from '../components/Map';
import { useToast } from '../contexts/ToastContext';
import '../pages/ServiceAddress.css';
import config from '../config';
import { FaCamera } from 'react-icons/fa';
import { LanguageContext } from '../contexts/LanguageContext'; // Importer LanguageContext
import './ServiceAddressFloorplan.css';

const ServiceAddressFloorplan = ({ behandlingsadrId, token, floorPlans, fetchServiceCardData, customerAddress }) => {
  const { translations } = useContext(LanguageContext); // Hent oversettelser fra LanguageContext
  const [isFloorPlansVisible, setIsFloorPlansVisible] = useState(false);
  const [selectedFloorPlanImage, setSelectedFloorPlanImage] = useState(null);
  const [checkpoints, setCheckpoints] = useState([]);
  const [svgIcons, setSvgIcons] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [description, setDescription] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [hoveredCheckpoint, setHoveredCheckpoint] = useState(null); // Ny state for hover-effekt
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [floorPlanToDelete, setFloorPlanToDelete] = useState(null);
  const fileInputRef = useRef(null);
  const mapRef = useRef(null);
  const notify = useToast();
  const imageRef = useRef(null); // Ref for image element

  useEffect(() => {
    if (selectedFloorPlanImage || checkpoints.length > 0) {
      setIsModalOpen(true);
    }
  }, [selectedFloorPlanImage, checkpoints]);

  const handleViewFloorPlanImage = async (floorplan_id) => {
    try {
      const imageResponse = await apiClient.get(`${config.apiUrl}/getFloorplanImage/${floorplan_id}?token=${token}`);
      setSelectedFloorPlanImage(imageResponse.data.data.image);

      const checkpointsResponse = await apiClient.get(`${config.apiUrl}/getCheckpointByFloorplan/${floorplan_id}?token=${token}`);
      const checkpointData = checkpointsResponse.data.data;
      setCheckpoints(checkpointData);

      // Fetch SVGs for each checkpoint and store them in state
      const svgFetchPromises = checkpointData.map(async (checkpoint) => {
        const iconSrc = `/assets/checkpoint-categories/${checkpoint.shape_type.toLowerCase()}.svg`;
        const response = await fetch(iconSrc);
        let svgText = await response.text();

        // Modifiserer SVG-en for å sette riktig fill og stroke
        svgText = svgText.replace(/<svg/, `<svg fill="${checkpoint.fill_color}" stroke="${checkpoint.line_color}"`);
        svgText = svgText.replace(/fill=".*?"/g, `fill="${checkpoint.fill_color}"`);
        svgText = svgText.replace(/stroke=".*?"/g, `stroke="${checkpoint.line_color}"`);

        return { checkpointId: checkpoint.checkpoint_id, svgContent: svgText };
      });

      const svgResults = await Promise.all(svgFetchPromises);
      const svgIconsMap = {};
      svgResults.forEach((item) => {
        svgIconsMap[item.checkpointId] = item.svgContent;
      });
      setSvgIcons(svgIconsMap);

    } catch (error) {
      console.error('Error fetching floor plan image or checkpoints:', error);
      notify('error', translations['fetchErrorFP'] || 'Failed to fetch floor plan image or checkpoints.');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFloorPlanImage(null);  // Reset state when modal closes
    setCheckpoints([]);               // Reset state when modal closes
    setSvgIcons({});                  // Reset SVG icons when modal closes
    setHoveredCheckpoint(null);       // Reset hover state
  };

  const openDeleteModal = (floorplan) => {
    setFloorPlanToDelete(floorplan);
    setDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setFloorPlanToDelete(null);
    setDeleteModalVisible(false);
  };

  const handleDeleteFloorPlan = async () => {
    if (!floorPlanToDelete) return;

    try {
      const response = await apiClient.delete(
        `${config.apiUrl}/delFloorplan/${floorPlanToDelete.floorplan_id}?token=${token}`
      );

      if (response.status === 200) {
        fetchServiceCardData(behandlingsadrId); // Oppdater listen etter sletting
        notify('success', translations['deleteSuccessFP'] || 'Floor plan deleted successfully.');
        closeDeleteModal();
      }
    } catch (error) {
      console.error('Error deleting floor plan:', error);
      notify('error', translations['deleteErrorFP'] || 'Failed to delete floor plan.');
    }
  };

  const handleMouseOver = (checkpoint) => {
    setHoveredCheckpoint(checkpoint);
  };

  const handleMouseOut = () => {
    setHoveredCheckpoint(null);
  };



  const renderCheckpoints = () => {
    const imageElement = imageRef.current;
    if (!imageElement) return null;

    const imageWidth = imageElement.naturalWidth;
    const imageHeight = imageElement.naturalHeight;

    return checkpoints.map((checkpoint) => {
      const { point_x, point_y, checkpoint_id, label } = checkpoint;
      const iconStyle = {
        position: 'absolute',
        left: `${(point_x / imageWidth) * 100}%`,
        top: `${(point_y / imageHeight) * 100}%`,
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
      };

      return (
        <div
          key={checkpoint_id}
          style={iconStyle}
          onMouseOver={() => handleMouseOver(checkpoint)}
          onMouseOut={handleMouseOut}
          onTouchStart={() => handleMouseOver(checkpoint)}  // For touch devices
          onTouchEnd={handleMouseOut}
        >
          <div dangerouslySetInnerHTML={{ __html: svgIcons[checkpoint_id] || '' }} />
          <div style={{ textAlign: 'center', fontSize: '12px', color: '#000', fontWeight: 'bold' }}>{label}</div>
          {hoveredCheckpoint === checkpoint && (
            <div style={{
              position: 'absolute',
              bottom: '100%',
              left: '50%',
              transform: 'translate(-50%, -10px)',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: '#fff',
              padding: '10px',
              borderRadius: '5px',
              zIndex: 1001,  // Økt z-index for å sikre at den vises foran andre elementer
              whiteSpace: 'nowrap',
              pointerEvents: 'none',
            }}>
              <p><strong>{translations['label'] || 'Label'}:</strong> {checkpoint.label}</p>
              <p><strong>{translations['barcode'] || 'Barcode'}:</strong> {checkpoint.barcode}</p>
              <p><strong>{translations['sequence'] || 'Sequence'}:</strong> {checkpoint.sequence}</p>
              <p><strong>{translations['type'] || 'Type'}:</strong> {checkpoint.checkpoint_type}</p>
              <p><strong>{translations['baitTrapType'] || 'Bait or Trap Type'}:</strong> {checkpoint.bait_type}</p>
              <p><strong>{translations['checkpointCategory'] || 'Checkpoint Category'}:</strong> {checkpoint.checkpoint_type}</p>
              <p><strong>{translations['room'] || 'Room'}:</strong> {checkpoint.room}</p>
              <p><strong>{translations['consumption'] || 'Consumption'}:</strong> {checkpoint.consumption}</p>
            </div>
          )}
        </div>
      );
    });
  };

  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
    setPreviewImage(null);
    setDocumentName('');
    setDescription('');
  };

  const openMapModal = () => {
    setIsMapModalOpen(true);
  };

  const closeMapModal = () => {
    setIsMapModalOpen(false);
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 1000,
        maxHeight: 1000,
        success: (compressedResult) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewImage(reader.result);
            setSelectedFile(compressedResult);
          };
          reader.readAsDataURL(compressedResult);
        },
      });
    }
  };

  const handleUpload = async () => {
    if (!documentName) {
      notify('error', translations['documentNameRequired'] || 'Document name is required.');
      return;
    }

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64data = reader.result;
        const data = {
          behandlingsadr_id: behandlingsadrId,
          floorplan_name: documentName,
          description: description,
          image: base64data
        };

        try {
          await apiClient.post(`${config.apiUrl}/addFloorPlan?token=${token}`, data);
          fetchServiceCardData(behandlingsadrId);
          closeUploadModal();
          notify('success', translations['uploadSuccess'] || 'Floor plan uploaded successfully.');
        } catch (error) {
          console.error('Error uploading floor plan:', error);
          notify('error', translations['uploadError'] || 'Failed to upload floor plan.');
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleTakeScreenshot = async () => {
    const map = mapRef.current.getMap();
    if (!map) {
      notify('error', translations['mapError'] || 'Map element not found!');
      return;
    }

    try {
      map.once('idle', () => {
        const canvas = map.getCanvas();
        const base64image = canvas.toDataURL('image/png');

        const data = {
          behandlingsadr_id: behandlingsadrId,
          floorplan_name: documentName,
          description: description,
          image: base64image
        };

        apiClient.post(`${config.apiUrl}/addFloorPlan?token=${token}`, data)
          .then(() => {
            fetchServiceCardData(behandlingsadrId);
            closeMapModal();
            notify('success', translations['screenshotSuccess'] || 'Screenshot taken and uploaded successfully.');
          })
          .catch((error) => {
            console.error('Error taking screenshot and uploading:', error);
            notify('error', translations['screenshotError'] || 'Failed to upload screenshot.');
          });
      });

      map.resize();
    } catch (error) {
      console.error('Error taking screenshot and uploading:', error);
      notify('error', translations['screenshotError'] || 'Failed to take screenshot.');
    }
  };

  return (
    <div className="expandable-section">
      <button className="expand-button" onClick={() => setIsFloorPlansVisible(!isFloorPlansVisible)}>
        {translations['floorPlans'] || 'Floor Plans'}
      </button>
      {isFloorPlansVisible && (
        <div className="expandable-content">
          <div className="button-group">
            <button className="button upload-button" onClick={openMapModal}>
              {translations['captureMap'] || 'Capture from Map'}
            </button>
            <button className="button upload-button" onClick={openUploadModal}>
              {translations['uploadCamera'] || 'Upload from Camera'}
            </button>
          </div>
          {floorPlans.length > 0 ? (
            floorPlans.map((floorPlan) => (
              <div key={floorPlan.floorplan_id} className="floorplan-card">
                <p><strong>{floorPlan.floorplan_name}</strong></p>
                <p>{floorPlan.description}</p>
                <p>
                  <button 
                    onClick={() => handleViewFloorPlanImage(floorPlan.floorplan_id)} 
                    style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
                    {translations['viewImage'] || 'View Image'}
                  </button>
                </p>
                <button onClick={() => openDeleteModal(floorPlan)}
                className="task-footer-button delete-button"
                style={{ marginLeft: 'auto' }}
                >                
                <FaTrashAlt /> {translations['delete'] || 'Delete'}
                </button>
              </div>
            ))
          ) : (
            <p>{translations['noFloorPlans'] || 'No Floor Plans'}</p>
          )}
        </div>
      )}

      <Modal
        isOpen={deleteModalVisible}
        onRequestClose={closeDeleteModal}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: '500px',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                    }
                }}
      >
        <h2>{translations['confirmDeleteFP'] || 'Confirm Delete'}</h2>
        <p>{translations['deleteConfirmationFP'] || 'Are you sure you want to delete this floor plan?'}</p>
        <button onClick={closeDeleteModal} className="task-footer-button resume-button">
                    {translations['cancel'] || 'Cancel'}
        </button>
        <button onClick={handleDeleteFloorPlan} className="task-footer-button delete-button">
          <FaTrashAlt /> {translations['confirmDeleteFP'] || 'Confirm Delete'}
        </button>
      </Modal>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        <button onClick={closeModal} className="close-modal-button">{translations['close'] || 'Close'}</button>
        <div style={{ position: 'relative' }}>
          {selectedFloorPlanImage && <img ref={imageRef} src={selectedFloorPlanImage} alt="Floor Plan" className="modal-image" />}
          {renderCheckpoints()}
        </div>
      </Modal>

      <Modal
        isOpen={isUploadModalOpen}
        onRequestClose={closeUploadModal}
        className="upload-modal"
        overlayClassName="upload-overlay"
      >
        <div className="upload-modal-content">
          <div className="upload-image-preview" onClick={handleFileClick}>
            {previewImage ? (
              <img src={previewImage} alt="Preview" className="preview-image" />
            ) : (
              <FaCamera className="camera-icon" />
            )}
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </div>
          <div className="upload-form">
            <h2>{translations['uploadFloorPlan'] || 'Upload Floor Plan'}</h2>
            <input
              type="text"
              placeholder={translations['documentName'] || 'Document Name'}
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
              className={`input-field ${documentName ? 'input-valid' : 'input-required'}`}
            />
            <input
              type="text"
              placeholder={translations['description'] || 'Description (Optional)'}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="input-field"
            />
            <button onClick={handleUpload} className="button upload-button">
              {translations['upload'] || 'Upload'}
            </button>
            <button onClick={closeUploadModal} className="button cancel-button">
              {translations['cancel'] || 'Cancel'}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isMapModalOpen}
        onRequestClose={closeMapModal}
        contentLabel="Map Modal"
        style={{
          content: {
            top: '100px',
            left: '50%',
            right: 'auto',
            bottom: '100px',
            marginRight: '-50%',
            transform: 'translate(-50%, 0)',
            width: '80%',
            height: 'calc(100% - 300px)',
          },
        }}
      >
        <button onClick={closeMapModal} className="close-modal-button">
          {translations['close'] || 'Close'}
        </button>
        <div style={{ marginBottom: '20px' }}>
          <input
            type="text"
            placeholder={translations['documentName'] || 'Document Name'}
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
            className={`input-field ${documentName ? 'input-valid' : 'input-required'}`}
            style={{ width: '100%', marginBottom: '10px' }}
          />
          <input
            type="text"
            placeholder={translations['description'] || 'Description (Optional)'}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="input-field"
            style={{ width: '100%', marginBottom: '10px' }}
          />
          <button onClick={handleTakeScreenshot} className="button upload-button">
            {translations['takeScreenshot'] || 'Take Screenshot'}
          </button>
        </div>
        <Map ref={mapRef} initialAddress={customerAddress} />
      </Modal>
    </div>
  );
};

export default ServiceAddressFloorplan;
