import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient';
import config from '../config';
import './CustomerNew.css';
import { useToast } from '../contexts/ToastContext';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import { LanguageContext } from '../contexts/LanguageContext';

const CustomerNew = () => {
  const { translations } = useContext(LanguageContext);
  const [customerData, setCustomerData] = useState({
    name: '',
    organizationNumber: '',
    extCustomerNo: '',
    category: '',
    contact: '',
    email: '',
    telephone: '',
    streetAddress: '',
    aptNo: '',
    postal: '',
    city: '',
  });
  const [categories, setCategories] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  const notify = useToast();

  // Load Google Maps API
  const libraries = useMemo(() => ['places'], []);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey, // Ensure this API key is correct and consistent with NewInspection.js
    libraries,
  });

  const autocompleteRef = useRef(null);

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place) {
        const addressComponents = place.address_components || [];
        const streetNumber = addressComponents.find(comp => comp.types.includes('street_number'))?.long_name || '';
        const route = addressComponents.find(comp => comp.types.includes('route'))?.long_name || '';
        const postalCode = addressComponents.find(comp => comp.types.includes('postal_code'))?.long_name || '';
        const city = addressComponents.find(comp => comp.types.includes('locality'))?.long_name ||
                     addressComponents.find(comp => comp.types.includes('postal_town'))?.long_name || '';

        setCustomerData(prevData => ({
          ...prevData,
          streetAddress: `${route} ${streetNumber}`,
          postal: postalCode,
          city: city,
        }));
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    apiClient
      .get(`${config.apiUrl}/getCustomerCategory?token=${token}`)
      .then((response) => {
        if (response.data.status === 'success') {
          setCategories(response.data.data);
        } else {
          notify('error', translations['fetchCategoriesError'] || 'Failed to fetch categories');
        }
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
        notify('error', translations['fetchCategoriesError'] || 'Error fetching categories');
      });
  }, [notify, translations]);

  useEffect(() => {
    const { name, category, streetAddress, postal, city } = customerData;
    setIsFormValid(name && category && streetAddress && postal && city);
  }, [customerData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  const handleSave = () => {
    const token = localStorage.getItem('token');
    const selectedCategory = categories.find(cat => cat.customer_cat === customerData.category);

    const data = {
      ext_cust_no: customerData.extCustomerNo || null,
      organization_number: customerData.organizationNumber,
      customer_cat_id: selectedCategory ? selectedCategory.customer_cat_id : null,
      navn: customerData.name,
      adresse: customerData.streetAddress,
      telefon: customerData.telephone,
      epost: customerData.email,
      kontakt: customerData.contact,
      postnr: customerData.postal,
      city: customerData.city,
      apt_no: customerData.aptNo,
    };

    apiClient.post(`${config.apiUrl}/addCustNew?token=${token}`, data)
      .then(response => {
        if (response.data.status === 'success') {
          const kundeId = response.data.kunde_id;
          notify('success', translations['customerCreatedSuccess'] || 'The new customer was created successfully!');
          navigate(`/customeroverview/${kundeId}`);
        } else {
          notify('error', translations['saveCustomerError'] || 'Failed to save customer');
        }
      })
      .catch(error => {
        console.error('Error saving customer:', error);
        notify('error', translations['saveCustomerError'] || 'Error saving customer');
      });
  };

  const handleCancel = () => {
    if (window.confirm(translations['cancelConfirmation'] || 'Are you sure you want to cancel?')) {
      notify('error', translations['customerCreationCancelled'] || 'Customer creation cancelled.');
      navigate('/mainmenu');
    }
  };

  const getInputClassName = (value, isMandatory) => {
    if (isMandatory) {
      return value ? 'customer-new-input-field filled' : 'customer-new-input-field mandatory';
    }
    return 'customer-new-input-field';
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <MainLayout title={translations['newCustomer'] || "New Customer"}>
      <div className="customer-new-container">
        <form className="customer-new-form">
          <div className="customer-new-form-group">
            <label>{translations['customerName'] || 'Customer Name'}</label>
            <input
              type="text"
              name="name"
              value={customerData.name}
              onChange={handleChange}
              placeholder={translations['enterCustomerName'] || "Enter customer name"}
              className={getInputClassName(customerData.name, true)}
              required
            />
          </div>
          <div className="customer-new-form-group">
            <label>{translations['organizationNumber'] || 'Organization Number'}</label>
            <input
              type="text"
              name="organizationNumber"
              value={customerData.organizationNumber}
              onChange={handleChange}
              placeholder={translations['enterOrganizationNumber'] || "Enter organization number (Optional)"}
              className="customer-new-input-field"
            />
          </div>
          <div className="customer-new-form-group">
            <label>{translations['extCustomerNo'] || 'Ext Customer no'}</label>
            <input
              type="text"
              name="extCustomerNo"
              value={customerData.extCustomerNo}
              onChange={handleChange}
              placeholder={translations['enterExtCustomerNo'] || "Enter external customer number (Optional)"}
              className="customer-new-input-field"
            />
          </div>
          <div className="customer-new-form-group">
            <label>{translations['category'] || 'Category'}</label>
            <select
              name="category"
              value={customerData.category}
              onChange={handleChange}
              className={getInputClassName(customerData.category, true)}
              required
            >
              <option value="">{translations['selectCategory'] || 'Select a category'}</option>
              {categories.map((cat) => (
                <option key={cat.customer_cat_id} value={cat.customer_cat}>
                  {cat.customer_cat}
                </option>
              ))}
            </select>
          </div>
          <div className="customer-new-form-group">
            <label>{translations['contact'] || 'Contact'}</label>
            <input
              type="text"
              name="contact"
              value={customerData.contact}
              onChange={handleChange}
              placeholder={translations['enterContactName'] || "Enter contact name"}
              className="customer-new-input-field"
            />
          </div>
          <div className="customer-new-form-group">
            <label>{translations['emailAddress'] || 'E-mail Address'}</label>
            <input
              type="email"
              name="email"
              value={customerData.email}
              onChange={handleChange}
              placeholder={translations['enterEmail'] || "Enter e-mail address (Optional)"}
              className={getInputClassName(customerData.email, false)}
            />
          </div>
          <div className="customer-new-form-group">
            <label>{translations['telephone'] || 'Telephone'}</label>
            <input
              type="tel"
              name="telephone"
              value={customerData.telephone}
              onChange={handleChange}
              placeholder={translations['enterTelephone'] || "Enter telephone number (Optional)"}
              className="customer-new-input-field"
            />
          </div>
          <div className="customer-new-form-group inline-group">
            <div className="customer-new-form-group street-address">
              <label>{translations['streetAddress'] || 'Street Address'}</label>
              <Autocomplete
                onLoad={(ref) => (autocompleteRef.current = ref)}
                onPlaceChanged={handlePlaceChanged}
                options={{ componentRestrictions: { country: 'no' } }}
              >
                <input
                  type="text"
                  name="streetAddress"
                  value={customerData.streetAddress}
                  onChange={handleChange}
                  placeholder={translations['enterStreetAddress'] || "Enter street address"}
                  className={getInputClassName(customerData.streetAddress, true)}
                />
              </Autocomplete>
            </div>
            <div className="customer-new-form-group apt-no">
              <label>{translations['aptNo'] || 'Apt No'}</label>
              <input
                type="text"
                name="aptNo"
                value={customerData.aptNo}
                onChange={handleChange}
                placeholder={translations['enterAptNo'] || "Enter apt number (Optional)"}
                className="customer-new-input-field"
              />
            </div>
          </div>
          <div className="customer-new-form-group inline-group">
            <div className="customer-new-form-group postal">
              <label>{translations['postalCode'] || 'Postal/Zip'}</label>
              <input
                type="text"
                name="postal"
                value={customerData.postal}
                onChange={handleChange}
                placeholder={translations['enterPostalCode'] || "Enter postal/zip code"}
                className={getInputClassName(customerData.postal, true)}
              />
            </div>
            <div className="customer-new-form-group city">
              <label>{translations['city'] || 'City'}</label>
              <input
                type="text"
                name="city"
                value={customerData.city}
                onChange={handleChange}
                placeholder={translations['enterCity'] || "Enter city"}
                className={getInputClassName(customerData.city, true)}
              />
            </div>
          </div>
          <div className="customer-new-button-group">
            <button
              type="button"
              className="customer-new-cancel-button"
              onClick={handleCancel}
            >
              {translations['cancel'] || 'Cancel'}
            </button>
            <button
              type="button"
              className="customer-new-next-button"
              onClick={handleSave}
              disabled={!isFormValid}
            >
              {translations['save'] || 'Save'}
            </button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default CustomerNew;
