// src/components/ControlPointModal.js
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import apiClient from '../api/apiClient';
import config from '../config';
import './ControlPointModal.css';

Modal.setAppElement('#root');

const ControlPointModal = ({ isOpen, onRequestClose, behandlingsadrId }) => {
  const [checkpoints, setCheckpoints] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (isOpen) {
      const fetchCheckpoints = async () => {
        try {
          const response = await apiClient.get(`${config.apiUrl}/getCheckpointByServiceAddress/${behandlingsadrId}?token=${token}`);
          if (response.data.status === 'success') {
            setCheckpoints(response.data.data);
          } else {
            console.error('Failed to fetch checkpoints');
          }
        } catch (error) {
          console.error('Error fetching checkpoints:', error);
        }
      };
      fetchCheckpoints();
    }
  }, [isOpen, behandlingsadrId, token]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Control Points"
      className="control-point-modal"
      overlayClassName="control-point-overlay"
    >
      <div className="modal-content">
        <h2>Control Points</h2>
        {checkpoints.length > 0 ? (
          checkpoints.map((checkpoint) => (
            <div key={checkpoint.checkpoint_id} className="checkpoint-item">
              <button className="accordion-cp-button" onClick={() => setCheckpoints((prev) =>
                prev.map(cp =>
                  cp.checkpoint_id === checkpoint.checkpoint_id
                    ? { ...cp, isOpen: !cp.isOpen }
                    : cp
                ))
              }>
                {`${checkpoint.checkpoint_type} - ${checkpoint.room}`}
              </button>
              {checkpoint.isOpen && (
                <div className="checkpoint-details">
                  <p><strong>Type:</strong> {checkpoint.checkpoint_type}</p>
                  <p><strong>Bait Type:</strong> {checkpoint.bait_type}</p>
                  <p><strong>Label:</strong> {checkpoint.label}</p>
                  <p><strong>Exact Location:</strong> {checkpoint.exact_location}</p>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No control points available.</p>
        )}
        <button className="button close-button" onClick={onRequestClose}>Close</button>
      </div>
    </Modal>
  );
};

export default ControlPointModal;