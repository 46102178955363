import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import apiClient from '../api/apiClient'; // Importer vår apiClient i stedet for 
import './DrivingRoutes.css'; // Importere ny CSS-fil
import config from '../config';
import { LanguageContext } from '../contexts/LanguageContext'; // Importere LanguageContext

const DrivingRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  const user_id = localStorage.getItem('userid'); // Hente userid fra localStorage
  const navigate = useNavigate();
  const { translations } = useContext(LanguageContext); // Hente oversettelser fra LanguageContext

  useEffect(() => {
    apiClient.get(`${config.apiUrl}/getDrivingRoutes/${user_id}/?token=${token}`)
      .then(response => {
        if (response.data.status === 'success') {
          setRoutes(response.data.data);
        }
      })
      .catch(error => {
        console.error('Error fetching routes:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token, user_id]);

  const handleRouteClick = (rute_omrade_id) => {
    navigate(`/addresses/${rute_omrade_id}`);
  };

  const handleAllRoutesClick = () => {
    navigate('/addresses/all');
  };

  const handleMapViewClick = () => {
    navigate('/map-view');
  };

  return (
    <MainLayout title={translations['drivingRoutes'] || 'Driving Routes'}>
      <div className="driving-routes-container">
        <button
          className="driving-route-button all-routes-button"
          onClick={handleAllRoutesClick}
        >
          {translations['allRoutes'] || 'All Routes'}
        </button>
        <button
          className="driving-route-button map-view-button"
          onClick={handleMapViewClick}
        > 
          {translations['mapView'] || 'Map View'}
        </button>  
        {loading ? (
          <p>{translations['loading'] || 'Loading...'}</p>
        ) : (
          routes.map(route => (
            <button
              key={route.rute_omrade_id}
              className="driving-route-button"
              onClick={() => handleRouteClick(route.rute_omrade_id)}
            >
              {route.omrade}
            </button>
          ))
        )}
      </div>
    </MainLayout>
  );
};

export default DrivingRoutes;
